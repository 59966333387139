 
<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
        <b-card title="Settings">
          <b-row>
            <b-col cols="6" md="6">
              <b-form-group
                label="Role name"
                label-for="input_role_name"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="role.name"
                  id="input_role_name"
                  placeholder="Set name of role"
                />
              </b-form-group>
            </b-col>
            </b-row>
            <b-row>
            <b-col cols="6" md="6">
              <b-button variant="primary" @click="saveRole">
                <feather-icon icon="SaveIcon" class="mr-25" />
                <span class="text-nowrap">Save role</span>
              </b-button>
            </b-col>
          </b-row>
          <!-- {{role.permissionIds}} -->
          <!-- <pre>{{permissionGroups}}</pre> -->
        </b-card>

        <div>
          <b-card
                v-for="(group, index) in permissionGroups"
                :id="index"
                :key="index"      
          >
          <h4>{{index}} <b-form-checkbox @change="togglePermissions(group, $event)"/></h4>

              <!-- Row Loop -->
              <b-row>
                <!-- Item Name -->
                <b-col md="12" style="display: flex; margin-bottom:55px;">
                  <div class="checkbox_container" style="clear: both;">
                    <b-form-checkbox
                          style="width:300px; float:left;"
                          v-for="(permission,permissionIndex) in group"
                          v-model="role.permissionIds"
                          :key="permissionIndex"
                          v-bind:value="permission.id"                          
                          ><template v-if="permission.method">{{permission.method}}</template><template v-else>{{permission.name}}</template></b-form-checkbox>

                  </div>
                </b-col>

                
              </b-row>
          </b-card>
        </div>
        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="exercise.parts.push({text:'',media:''})"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Add New</span>
        </b-button> -->

        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="saveExercise"
          style="margin-left:1em;"
        >
          <feather-icon icon="SaveIcon" class="mr-25" />
          <span>Save</span>
        </b-button> -->

    </b-overlay>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
  BOverlay,
  BRow,
  BCol,
  BButton,
  BCard,
  BEmbed,
  BSidebar,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BOverlay,
    quillEditor,
    BCard,
    BEmbed,
    BSidebar,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading:true,
      showSidebar: false,
      permissions:[],
      role:{
        permissions:[]
      },
      
      

      //content: `<h2><span class="ql-font-serif">Quill Rich Text Editor</span></h2><br /><p>Quill is a free, <a href="https://github.com/quilljs/quill/">open source</a> WYSIWYG editor built for the modern web. With its <a href="http://quilljs.com/docs/modules/">modular architecture</a> and expressive <a href="http://quilljs.com/docs/api/">API</a>, it is completely customizable to fit any need.</p><br />`,

    };
  },
  computed: {
    permissionGroups() {
      var groups = {
        custom:[],
        default:[],
      };
      this.permissions.forEach(permission=>{
        if (permission.controller) {
            if (!groups[permission.controller]) {
              groups[permission.controller] = [];
            }

            groups[permission.controller].push(permission)
        }
        else if (permission.custom){
          groups.custom.push(permission)
        }
        else {
          if (groups[permission.page]) groups[permission.page].unshift(permissions)
          else groups.default.push(permission)
        }
      })
      return groups;
    }
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    togglePermissions(group, state) {
      //console.log(group, state)
      group.forEach(permission=>{
        if (state) {
            if (!this.role.permissionIds.includes(permission.id)) this.role.permissionIds.push(permission.id);
        }
        else {
            if (this.role.permissionIds.includes(permission.id)) {
              const index = this.role.permissionIds.indexOf(permission.id);
              if (index > -1) { // only splice array when item is found
                this.role.permissionIds.splice(index, 1); // 2nd parameter means remove one item only
              } 
            }
        }
      })
    },
    
    async loadPermissions() {
      this.showSidebar=false;
      var url = '/permissions';
      const res = await this.callApi('get', url);
      this.permissions = res.data.result;
      
      //this.updateLoading();
    },
    async loadRole(id) {
      this.showSidebar=false;
      var url = '/roles/'+id;
      const res = await this.callApi('get', url);
      this.role = res.data.result;
      //this.role.permissionIds = [71]
      this.updateLoading();
    },
    async saveRole() {
      this.showSidebar=false;
      var url = '/roles';
      var method = 'post'
      this.isLoading = true;
      if (this.role.id) {
          url = '/roles/'+this.role.id
          method = 'put'
      }
        

      const res = await this.callApi(method, url, this.role);

      //console.log(res.data.result.id)
      if (res.data.result.id && method=="post") {
        this.$router.push({name:'role-id', params:{id:res.data.result.id}})
        this.loadRole(res.data.result.id);
      }
      else {
        this.loadRole(this.$route.params.id);
      }

    },
    updateLoading() {
      
      if (this.$route.params.id &&
        this.role.name
      ) {
        this.isLoading = false;
      }
      else if (!this.$route.params.id) {
        this.isLoading = false;
      }
    },
    
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  mounted() {
    this.loadPermissions();
    if (this.$route.params.id) {
      this.loadRole(this.$route.params.id);
    }
    else {
      this.isLoading=false;
    }
  }
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.quill-editor {
  width:100%;
}
</style>
 